
import { getLabelByValue, roomChargeTypes, gameTypes, gamePlayerTypes, DeskFanList, actTypes, hnmjCardImgList, getIconByValue, ActTypeFlower, autoCountDissTypes, IsPkType, IsSsPkType, IsLldPkType } from "@/common/head";
import http from "@/common/http";
import ActionInfo from "./ActionInfo.vue";
import RuleInfo from "../us_club/RuleInfo.vue";
import MatchInfo from "../us_club/MatchInfo.vue";
import { computed, defineComponent, nextTick, onMounted, ref } from "vue";
const columns = [
  {
    title: "userID",
    dataIndex: "userId",
    with: 100,
  },
  {
    title: "游戏ID",
    dataIndex: "maskId",
    with: 100,
  },
  {
    title: "昵称",
    dataIndex: "nickName",
    with: 100,
  },
  {
    title: "头像",
    dataIndex: "icon",
    with: 100,
  },
  {
    title: "结算分数",
    dataIndex: "score",
    with: 100,
  },
];

//场次
const gameInfoLosColums = [
  {
    title: "当前场次",
    dataIndex: "round",
    with: 100,
  },
  {
    title: "回放码",
    dataIndex: "backCode",
    with: 100,
  },

  {
    title: "庄家",
    dataIndex: "bankerUserId",
    with: 100,
  },

  {
    title: "玩家UserID",
    dataIndex: "userIds",
    with: 100,
  },

  {
    title: "玩家游戏ID",
    dataIndex: "maskIds",
    with: 260,
  },

  {
    title: "玩家昵称",
    dataIndex: "nickNames",
    with: 260,
  },

  {
    title: "结算分数",
    dataIndex: "scoreCounts",
    with: 100,
  },

  {
    title: "时间",
    dataIndex: "currTime",
    with: 180,
  },

  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 140,
    align: "center",
  },
];
export default defineComponent({
  props: {
    id: {
      type: Number,
    },
    tableName: {
      type: String,
    },
  },
  components: {
    ActionInfo,
    RuleInfo,
    MatchInfo,
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const ruleInfo = ref<any>({});
    const dataSource = ref<any>({
      roomId: "",
      deskId: "",
      payType: "",
      roomCode: "",
      gameType: 0,
      playerType: "",
      basePoint: "",
      initGameCount: "",
      playGameCount: "",
      createUserId: "",
      createNickName: "",
      userIds: "",
      maskIds: "",
      nickNames: "",
      icons: "",
      scoreCountAll: "",
      startTime: "",
      endTime: "",
      deskRemark: "",
      deskRule: "",
      deskRules: {
        // isFan: false,
        // cappingRate: 0,
        // isDealer: false,
        // isRTD: false,
        // isUpG: false,
        // isDraw: false,
        // isFlower: false,
        // isFlowerDoub: false,
        // isChaosColorDoub: false,
        // isNotDAW: false,
        // isToken: false,
        // isHook: false,
        // isSeabed: false,
        // isThreeFour: true,
        // isPengExposed: false,
        // isRandomDealer: false,
        // isRandomPosIndex: false,
        // isRead: false,
        // isDissAll: false,
        // setNumG: 0,
        // isNotEat: false,
        // isNotPao: false,
        // isAuto: 0,
        // isAuthCount: 1,
      },
      dismissUserId: "",
      dismissMaskId: "",
      dismissNickNames: "",
      dismissTime: "",
    });

    const createMaskId = ref(0);

    //点击取消
    const onClose = () => {
      emit("update:id", 0);
      emit("close");
    };

    const userList = ref<any>([]);
    const gameInfoLogs = ref<any>([]);
    const actionLogs = ref<any>({});
    const actionLogsVisible = ref(false);
    const actionsLogsId = ref(0);
    const actionsLogsTableName = ref("");

    //获取数据
    const getData = async () => {
      loading.value = true;
      const { data } = await http.get(`us_group_info_logs/${props.tableName}/${props.id}`);
      dataSource.value = data.data;
      gameInfoLogs.value = data.gameInfoLogs;
      createMaskId.value = data.createMaskId;
      ruleInfo.value = data.ruleInfo;

      //格式化玩家信息
      const userIds = dataSource.value.userIds.split(",");
      const maskIds = dataSource.value.maskIds.split(",");
      const nickNames = dataSource.value.nickNames.split(",");
      const icons = dataSource.value.icons.split(",");
      const scoreCountAll = dataSource.value.scoreCountAll.split(",");
      userIds.forEach((item: any, index: any) => {
        userList.value.push({
          userId: item,
          maskId: maskIds[index],
          nickName: nickNames[index],
          icon: icons[index],
          score: scoreCountAll[index],
        });
      });

      dataSource.value.deskRules = JSON.parse(dataSource.value.deskRule);

      loading.value = false;
    };

    //查看某一场次的行牌记录
    const getActions = async (row: any) => {
      actionsLogsId.value = row.id;
      actionsLogsTableName.value = row.tableName;
      nextTick(() => {
        actionLogsVisible.value = true;
      });
    };

    onMounted(() => {
      getData();
    });

    //当前选中的是否扑克
    const isPkType = computed(() => {
      return IsPkType(dataSource.value.gameType as number);
    });

    //是否陵水十三张
    const isSsPkType = computed(() => {
      return IsSsPkType(dataSource.value.gameType as number);
    });

    //是否儋州地主牌
    const isLldPkType = computed(() => {
      return IsLldPkType(dataSource.value.gameType as number);
    });

    return {
      loading,
      onClose,
      dataSource,
      gameTypes,
      roomChargeTypes,
      getLabelByValue,
      gamePlayerTypes,
      columns,
      userList,
      DeskFanList,
      gameInfoLosColums,
      gameInfoLogs,
      getActions,
      createMaskId,
      actionLogs,
      actTypes,
      hnmjCardImgList,
      ActTypeFlower,
      getIconByValue,
      actionLogsVisible,
      actionsLogsId,
      actionsLogsTableName,
      autoCountDissTypes,
      isPkType,
      isSsPkType,
      isLldPkType,
      ruleInfo,
    };
  },
});
