<template>
  <div :style="{ padding: !isGroupInfo ? '20px' : '0' }">
    <template v-if="!isGroupInfo">
      <a-descriptions title="排名赛规则代码"> </a-descriptions>
      <json-viewer :value="ruleData" boxed sort />

      <div style="height: 30px"></div>
    </template>

    <a-descriptions title="排名赛规则信息" style="margin-top: 10px"> </a-descriptions>

    <a-card hoverable>
      <div class="l-match-str-rule-item">
        <span style="margin-right: 10px"> 门票支付方式 </span>

        <a-radio-group v-model:value="ruleData.payment" name="radioGroup" readonly>
          <a-radio :value="item.value" v-for="(item, index) in MatchPayTypeList" :key="index">{{ item.label }}</a-radio>
        </a-radio-group>
      </div>

      <div class="l-match-str-rule-item">
        <span style="margin-right: 10px"> 淘汰预警值 </span>
        <span class="l-match-score-box">{{ ruleData.obsoleteWarn }}</span>

        <span style="margin-right: 10px; margin-left: 40px"> 准入值 </span>
        <span class="l-match-score-box">{{ ruleData.initJoin }}</span>
      </div>

      <div class="l-match-str-rule-item">
        <a-checkbox :checked="ruleData.isSafeguard === true" readonly>负分保护</a-checkbox>
      </div>

      <div class="l-match-str-rule-item">
        <div v-for="(item, index) in ruleData.pointRang" :key="index" style="margin-bottom: 5px">
          分数 <span class="l-match-score-box">{{ item.startRang }}</span> 至 <span class="l-match-score-box"> {{ item.endRang }}</span> <span>扣除比赛分</span><span class="l-match-score-box">{{ item.value }}</span>
        </div>
      </div>

      <div class="l-match-str-rule-item">
        <a-checkbox :checked="ruleData.bigWin === true" readonly>大赢家分数小于最低分玩家均摊总比赛分</a-checkbox>
        <span class="l-match-score-box">{{ ruleData.bigValue }}</span>
      </div>
    </a-card>
  </div>
</template>

<script lang="ts">
import { getLabelByValue, MatchPayTypeList } from "@/common/head";
import { getValue } from "@/common/utils";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    ruleStr: {
      type: String,
      required: true,
    },
    isGroupInfo: {
      type: Boolean,
      defaule: false,
    },
  },
  setup(props) {
    const ruleData = JSON.parse(props.ruleStr);

    return {
      ruleData,
      MatchPayTypeList,
      getValue,
      getLabelByValue,
    };
  },
});
</script>

<style lang="scss" scoped>
.l-match-score-box {
  display: inline-block;
  background: #f2f2f2;
  width: 80px;
  padding: 2px 5px;
  border-radius: 5px;
  text-align: center;
  // color: orangered;
}

.l-match-str-rule-item {
  margin-bottom: 20px;
}
</style>
