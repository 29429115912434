
import { ActTypeIsTrusteeshipr, getLabelByValue, actTypes, ActTypeFlower, getIconByValue, hnmjCardImgList, pokerCardImgList, IsPkType, PokerActTypes } from "@/common/head";
import http from "@/common/http";
import { getValue } from "@/common/utils";
import dayjs from "dayjs";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    id: {
      type: Number,
    },
    tableName: {
      type: String,
    },
    gameType: {
      type: Number,
      required: true,
    },
  },
  components: {},
  setup(props) {
    const loading = ref(false);
    const actionLogs = ref<any>({
      allAction: [],
      deskInfo: {
        agentInfo: [],
      },
    });

    const searchUserId = ref<undefined | number>(undefined);

    //查看某一场次的行牌记录
    const getActions = async () => {
      loading.value = true;
      const { data } = await http.get(`game_info_logs/${props.tableName}/${props.id}/action_logs`);
      actionLogs.value = JSON.parse(data.actionLogs.jsonField);

      loading.value = false;
    };

    //当前选中的是否扑克
    const isPkType = computed(() => {
      return IsPkType(props.gameType as number);
    });

    const allAction = computed(() => {
      //判断托管
      const result: any = [];
      let tgdata: any = {};
      actionLogs.value.allAction.forEach((item: any) => {
        if ((searchUserId.value && item.userId == searchUserId.value) || !searchUserId.value) {
          if (item.action == ActTypeIsTrusteeshipr && item.isTrusteeship === true) {
            if (item.isTrusteeship === true) {
              //如果动作是托管，且托管为true，则他之后的活动都设置为托管
              tgdata[item.userId] = true;
            } else {
              //之后的动作设置为不托管
              delete tgdata[item.userId];
            }
          }
          item.isTg = getValue(tgdata, item.userId, false);

          //查找玩家信息
          const userItem = actionLogs.value.deskInfo.agentInfo.find((itm: any) => {
            return itm.id == item.userId;
          });

          if (userItem) {
            item.name = userItem.name;
            item.maskId = userItem.maskId;
          }

          result.push(item);
        }
      });

      return result;
    });

    onMounted(() => {
      getActions();
    });

    return {
      actionLogs,
      getLabelByValue,
      actTypes,
      ActTypeFlower,
      getIconByValue,
      hnmjCardImgList,
      loading,
      dayjs,
      isPkType,
      PokerActTypes,
      pokerCardImgList,
      searchUserId,
      allAction,
    };
  },
});
