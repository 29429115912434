
import http from "@/common/http";
import { getLabelByValue, roomChargeTypes, gameTypes, gamePlayerTypes } from "@/common/head";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { dateIsBetween, getPageOffset } from "@/common/utils";
import Info from "./Info.vue";
import { Dayjs } from "dayjs";
import { PaginationProps } from "ant-design-vue";

const columns = [
  {
    title: "序号",
    width: 80,
    dataIndex: "index",
    // fixed: "left",
  },

  {
    title: "房间ID",
    width: 80,
    dataIndex: "roomId",
    ellipsis: true,
  },

  {
    title: "桌子ID",
    width: 80,
    dataIndex: "deskId",
    ellipsis: true,
  },

  {
    title: "付费类型",
    width: 90,
    dataIndex: "payType",
    ellipsis: true,
  },

  {
    title: "邀请码",
    width: 90,
    dataIndex: "roomCode",
    ellipsis: true,
  },

  {
    title: "游戏类型",
    width: 120,
    dataIndex: "gameType",
    ellipsis: true,
  },

  {
    title: "游戏人数",
    width: 80,
    dataIndex: "playerType",
    ellipsis: true,
  },

  {
    title: "基础分",
    width: 70,
    dataIndex: "basePoint",
    ellipsis: true,
  },

  {
    title: "倍率",
    width: 70,
    dataIndex: "rateNum",
    ellipsis: true,
  },

  {
    title: "开的局数",
    width: 80,
    dataIndex: "initGameCount",
    ellipsis: true,
  },

  {
    title: "真实局数",
    width: 80,
    dataIndex: "playGameCount",
    ellipsis: true,
  },

  {
    title: "创建人",
    width: 250,
    dataIndex: "createUser",
  },

  {
    title: "参与人的userID",
    width: 240,
    dataIndex: "userIds",
  },

  {
    title: "参与人的游戏ID",
    width: 280,
    dataIndex: "maskIds",
  },

  {
    title: "参与人的昵称",
    width: 400,
    dataIndex: "nickNames",
  },

  {
    title: "分数",
    width: 100,
    dataIndex: "scoreCountAll",
    ellipsis: true,
  },

  {
    title: "开始-结束时间",
    width: 320,
    dataIndex: "timeRange",
    ellipsis: true,
  },

  {
    title: "桌子描述",
    width: 120,
    dataIndex: "deskRemark",
    ellipsis: true,
  },

  // {
  //   title: "桌子规则",
  //   width: 120,
  //   dataIndex: "deskRule",
  //   ellipsis: true,
  // },

  {
    title: "解散发起人userId",
    width: 140,
    dataIndex: "dismissUserId",
    ellipsis: true,
  },

  {
    title: "解散发起人游戏ID",
    width: 140,
    dataIndex: "dismissMaskId",
    ellipsis: true,
  },

  {
    title: "解散发起人昵称",
    width: 140,
    dataIndex: "dismissNickNames",
    ellipsis: true,
  },

  {
    title: "解散时间",
    width: 180,
    dataIndex: "dismissTime",
    ellipsis: true,
  },

  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 100,
    align: "center",
  },
];

export default defineComponent({
  name: "us_group_info_logs",
  components: {
    Info,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        imPlayerId: undefined,
        gameType: undefined,
        userId: undefined,
        startTime: "",
        endTime: "",
        roomId: undefined,
        deskId: undefined,
        payType: undefined,
        roomCode: "",
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    const infoId = ref(0);
    const infoTableName = ref("");
    const infoVisible = ref(false);

    //开始结束时间
    const validTimeRange = reactive({
      minDate: "",
      maxDate: "",
    });

    //获取不可选择的时间
    const disabledDate = (currentDate: Dayjs) => {
      return !dateIsBetween(validTimeRange.minDate, validTimeRange.maxDate, currentDate);
    };

    //首次进入，获取可选的日期范围
    const getTime = async () => {
      loading.value = true;
      const { data } = await http.get("us_group_info_logs/time");
      validTimeRange.minDate = data.minDate;
      validTimeRange.maxDate = data.maxDate;
      search.data.startTime = data.maxDate;
      search.data.endTime = data.maxDate;

      onSearch();
    };

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_group_info_logs", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      let offset = getPageOffset(pagination.current!, pagination.pageSize!);
      data.value = result.data.data.map((item: any) => {
        item.index = ++offset;
        return item;
      });

      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = validTimeRange.maxDate;
      search.data.endTime = validTimeRange.maxDate;
      onSearch();
    };

    //显示详情
    const showInfo = (row: any) => {
      infoId.value = row.id;
      infoTableName.value = row.tableName;
      nextTick(() => {
        infoVisible.value = true;
      });
    };

    onMounted(() => {
      getTime();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      roomChargeTypes,
      gameTypes,
      gamePlayerTypes,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      disabledDate,
      getLabelByValue,
      showInfo,
      infoId,
      infoVisible,
      infoTableName,
    };
  },
});
