<template>
  <div class="l-model-container l-action-info-contianer">
    <div style="margin-top: 50px; text-align: center" v-show="loading">
      <a-spin :spinning="loading" size="large" tip="查询中..">.</a-spin>
    </div>

    <a-form ref="formRef" :labelCol="{ style: { width: '80px' } }">
      <a-form-item label="搜索玩家" name="is_read">
        <a-select v-model:value="searchUserId" placeholder="搜索玩家" allowClear style="width: 400px" @change="onPlayerSearch">
          <a-select-option :value="item.id" v-for="(item, index) in actionLogs.deskInfo.agentInfo" :key="index">
            <span> userId：{{ item.id }}</span>
            <span style="margin-left: 8px">
              游戏ID：<b>{{ item.maskId }}</b></span
            >
            <span style="margin-left: 8px"> 昵称：{{ item.name }}</span>
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>

    <div v-for="(item, index) in allAction" :key="index" style="margin-bottom: 40px">
      <a-divider orientation="left">
        <b style="font-size: 20px" class="l-font-primary">
          <span v-show="!isPkType"> {{ getLabelByValue(actTypes, item.action) }}</span>
          <span v-show="isPkType"> {{ getLabelByValue(PokerActTypes, item.action) }}</span>
        </b>
      </a-divider>
      <a-descriptions title="" bordered>
        <a-descriptions-item label="玩家">
          <span> userId：{{ item.userId }}</span>
          <span style="margin-left: 8px">
            游戏ID：<b>{{ item.maskId }}</b></span
          >
          <span style="margin-left: 8px"> 昵称：{{ item.name }}</span>
        </a-descriptions-item>
        <!-- <a-descriptions-item label="动作">
          <span v-show="!isPkType"> {{ getLabelByValue(actTypes, item.action) }}</span>
          <span v-show="isPkType"> {{ getLabelByValue(PokerActTypes, item.action) }}</span>
        </a-descriptions-item> -->
        <a-descriptions-item label="动作时间">{{ dayjs.unix(parseInt(item.actTime)).format("YYYY-MM-DD HH:mm:ss") }} </a-descriptions-item>
        <a-descriptions-item label="位置索引">{{ item.posIndex }}</a-descriptions-item>
        <a-descriptions-item label="是否托管">
          <a-tag color="success" v-show="item.isTg === true">是</a-tag>
          <a-tag color="error" v-show="item.isTg === false">否</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="发生动作的牌" v-if="item.actionCard">
          <template v-if="item.action != ActTypeFlower">
            <div class="l-flex-mj-box">
              <img :src="'/static/images/game/hnmj/' + getIconByValue(hnmjCardImgList, item.actionCard)" v-if="!isPkType" />
              <img :src="'/static/images/game/poker/' + getIconByValue(pokerCardImgList, item.actionCard)" v-else />
            </div>
          </template>
          <template v-else>
            <div class="l-flex l-flex-wrap">
              <div class="l-flex-mj-box" v-for="(itm, idx) in item.actionCard.flower" :key="idx">
                <img :src="'/static/images/game/hnmj/' + getIconByValue(hnmjCardImgList, itm)" v-if="!isPkType" />
                <img :src="'/static/images/game/poker/' + getIconByValue(pokerCardImgList, itm)" v-else />
              </div>
              <div class="l-flex-mj-box" style="margin-left: 20px">
                <img :src="'/static/images/game/hnmj/' + getIconByValue(hnmjCardImgList, item.actionCard.card)" v-if="!isPkType" />
                <img :src="'/static/images/game/poker/' + getIconByValue(pokerCardImgList, item.actionCard.card)" v-else />
              </div>
            </div>
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="进牌区" :span="3" v-if="item.inCard && item.inCard.length > 0">
          <div class="l-flex l-flex-wrap" v-for="(itm, idx) in item.inCard" :key="idx">
            <div class="l-flex-mj-box" v-for="(icon, i) in itm" :key="i">
              <img :src="'/static/images/game/hnmj/' + getIconByValue(hnmjCardImgList, icon)" v-if="!isPkType" />
              <img :src="'/static/images/game/poker/' + getIconByValue(pokerCardImgList, icon)" v-else />
            </div>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="弃牌区" :span="3">
          <div class="l-flex l-flex-wrap" v-if="item.outCard && item.outCard.length > 0">
            <div class="l-flex-mj-box" v-for="(itm, idx) in item.outCard" :key="idx">
              <img :src="'/static/images/game/hnmj/' + getIconByValue(hnmjCardImgList, itm)" v-if="!isPkType" />
              <img :src="'/static/images/game/poker/' + getIconByValue(pokerCardImgList, itm)" v-else />
            </div>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="手牌" :span="4">
          <div class="l-flex l-flex-wrap">
            <div class="l-flex-mj-box" v-for="(itm, idx) in item.handCard" :key="idx">
              <img :src="'/static/images/game/hnmj/' + getIconByValue(hnmjCardImgList, itm)" v-if="!isPkType" />
              <img :src="'/static/images/game/poker/' + getIconByValue(pokerCardImgList, itm)" v-else />
            </div>
          </div>
          <div>牌型代码：{{ item.handCard }}</div>
        </a-descriptions-item>
        <a-descriptions-item label="底牌" :span="3">
          <div class="l-flex l-flex-wrap" v-if="item.surpluCard">
            <div class="l-flex-mj-box" v-for="(itm, idx) in JSON.parse(item.surpluCard)" :key="idx">
              <img :src="'/static/images/game/hnmj/' + getIconByValue(hnmjCardImgList, itm)" v-if="!isPkType" />
              <img :src="'/static/images/game/poker/' + getIconByValue(pokerCardImgList, itm)" v-else />
            </div>
          </div>
          <div>牌型代码：{{ item.surpluCard }}</div>
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script lang="ts">
import { ActTypeIsTrusteeshipr, getLabelByValue, actTypes, ActTypeFlower, getIconByValue, hnmjCardImgList, pokerCardImgList, IsPkType, PokerActTypes } from "@/common/head";
import http from "@/common/http";
import { getValue } from "@/common/utils";
import dayjs from "dayjs";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    id: {
      type: Number,
    },
    tableName: {
      type: String,
    },
    gameType: {
      type: Number,
      required: true,
    },
  },
  components: {},
  setup(props) {
    const loading = ref(false);
    const actionLogs = ref<any>({
      allAction: [],
      deskInfo: {
        agentInfo: [],
      },
    });

    const searchUserId = ref<undefined | number>(undefined);

    //查看某一场次的行牌记录
    const getActions = async () => {
      loading.value = true;
      const { data } = await http.get(`game_info_logs/${props.tableName}/${props.id}/action_logs`);
      actionLogs.value = JSON.parse(data.actionLogs.jsonField);

      loading.value = false;
    };

    //当前选中的是否扑克
    const isPkType = computed(() => {
      return IsPkType(props.gameType as number);
    });

    const allAction = computed(() => {
      //判断托管
      const result: any = [];
      let tgdata: any = {};
      actionLogs.value.allAction.forEach((item: any) => {
        if ((searchUserId.value && item.userId == searchUserId.value) || !searchUserId.value) {
          if (item.action == ActTypeIsTrusteeshipr && item.isTrusteeship === true) {
            if (item.isTrusteeship === true) {
              //如果动作是托管，且托管为true，则他之后的活动都设置为托管
              tgdata[item.userId] = true;
            } else {
              //之后的动作设置为不托管
              delete tgdata[item.userId];
            }
          }
          item.isTg = getValue(tgdata, item.userId, false);

          //查找玩家信息
          const userItem = actionLogs.value.deskInfo.agentInfo.find((itm: any) => {
            return itm.id == item.userId;
          });

          if (userItem) {
            item.name = userItem.name;
            item.maskId = userItem.maskId;
          }

          result.push(item);
        }
      });

      return result;
    });

    onMounted(() => {
      getActions();
    });

    return {
      actionLogs,
      getLabelByValue,
      actTypes,
      ActTypeFlower,
      getIconByValue,
      hnmjCardImgList,
      loading,
      dayjs,
      isPkType,
      PokerActTypes,
      pokerCardImgList,
      searchUserId,
      allAction,
    };
  },
});
</script>

<style lang="scss" scoped>
.l-flex-mj-box {
  height: 40px;
  width: 30px;
}

.l-action-info-contianer {
  ::v-deep .ant-descriptions-item-label {
    padding: 5px 10px;
  }

  ::v-deep .ant-descriptions-item-content {
    padding: 5px 10px;
  }
}
</style>
