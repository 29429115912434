<template>
  <div>
    <a-spin :spinning="loading">
      <a-descriptions title="基础信息">
        <a-descriptions-item label="付费类型">{{ getLabelByValue(roomChargeTypes, dataSource.payType) }} </a-descriptions-item>
        <a-descriptions-item label="邀请码">{{ dataSource.roomCode }}</a-descriptions-item>
        <a-descriptions-item label="游戏类型">{{ getLabelByValue(gameTypes, dataSource.gameType) }}</a-descriptions-item>
        <a-descriptions-item label="玩家人数">{{ getLabelByValue(gamePlayerTypes, dataSource.playerType) }} </a-descriptions-item>
        <a-descriptions-item label="基础分">{{ dataSource.basePoint }}</a-descriptions-item>
        <a-descriptions-item label="开的局数">{{ dataSource.initGameCount }}</a-descriptions-item>
        <a-descriptions-item label="真实玩的局数">{{ dataSource.playGameCount }}</a-descriptions-item>
        <a-descriptions-item label="创建人">
          {{ dataSource.createNickName }} (userID：<span class="l-font-num">{{ dataSource.createUserId }}</span
          >，游戏ID：<span class="l-font-num">{{ createMaskId }}</span
          >)
        </a-descriptions-item>
        <a-descriptions-item label="开始结束时间"> {{ dataSource.startTime }} ~ {{ dataSource.endTime }} </a-descriptions-item>
      </a-descriptions>

      <a-divider />
      <a-descriptions title="桌子信息">
        <a-descriptions-item label="房间号">{{ dataSource.roomId }}</a-descriptions-item>
        <a-descriptions-item label="桌子号">{{ dataSource.deskId }}</a-descriptions-item>
        <a-descriptions-item label="桌子描述">{{ dataSource.deskRemark }}</a-descriptions-item>
      </a-descriptions>

      <div v-if="dataSource.deskRule != ''">
        <RuleInfo :game-type="dataSource.gameType" :rule-str="dataSource.deskRule" :is-group-info="true" />
      </div>

      <div v-if="dataSource.ruleId && ruleInfo.matchStr">
        <MatchInfo :game-type="dataSource.gameType" :rule-str="ruleInfo.matchStr" :is-group-info="true" />
      </div>

      <a-divider />
      <a-descriptions title="组局结算信息"> </a-descriptions>
      <a-table :columns="columns" :data-source="userList" :pagination="false" row-key="userId">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'icon'">
            <a-avatar :src="record.icon" />
          </template>

          <template v-if="column.dataIndex === 'score'">
            <span class="l-bold" :class="{ 'l-font-success': record.score > 0, 'l-font-danger': record.score < 0 }">
              {{ record.score }}
            </span>
          </template>
        </template>
      </a-table>

      <a-divider />
      <a-descriptions title="场次列表"> </a-descriptions>
      <a-table :columns="gameInfoLosColums" :data-source="gameInfoLogs" :pagination="false" row-key="round">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'backCode'">
            <span class="l-font-primary">{{ record.backCode }}</span>
          </template>

          <template v-if="column.dataIndex === 'round'">
            第
            <span class="l-font-num l-bold" style="margin: 0 5px">{{ record.round }}</span>
            场
          </template>

          <template v-if="column.dataIndex === 'action'">
            <l-auth-text-button auth="us_group_info_logs_info_btn" type="primary" primary @click="getActions(record)">
              <ExclamationCircleOutlined />
              玩家动作
            </l-auth-text-button>
          </template>
        </template>
      </a-table>
    </a-spin>
    <div class="l-drawer-edit-footer">
      <a-button style="margin-right: 8px" @click="onClose">关闭</a-button>
    </div>

    <a-modal v-model:visible="actionLogsVisible" title="玩家动作详情" width="90%" centered destroyOnClose :bodyStyle="{ padding: '0' }">
      <template #footer>
        <a-button key="back" @click="actionLogsVisible = false">关闭</a-button>
      </template>
      <action-info :game-type="dataSource.gameType" :id="actionsLogsId" :table-name="actionsLogsTableName"> </action-info>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { getLabelByValue, roomChargeTypes, gameTypes, gamePlayerTypes, DeskFanList, actTypes, hnmjCardImgList, getIconByValue, ActTypeFlower, autoCountDissTypes, IsPkType, IsSsPkType, IsLldPkType } from "@/common/head";
import http from "@/common/http";
import ActionInfo from "./ActionInfo.vue";
import RuleInfo from "../us_club/RuleInfo.vue";
import MatchInfo from "../us_club/MatchInfo.vue";
import { computed, defineComponent, nextTick, onMounted, ref } from "vue";
const columns = [
  {
    title: "userID",
    dataIndex: "userId",
    with: 100,
  },
  {
    title: "游戏ID",
    dataIndex: "maskId",
    with: 100,
  },
  {
    title: "昵称",
    dataIndex: "nickName",
    with: 100,
  },
  {
    title: "头像",
    dataIndex: "icon",
    with: 100,
  },
  {
    title: "结算分数",
    dataIndex: "score",
    with: 100,
  },
];

//场次
const gameInfoLosColums = [
  {
    title: "当前场次",
    dataIndex: "round",
    with: 100,
  },
  {
    title: "回放码",
    dataIndex: "backCode",
    with: 100,
  },

  {
    title: "庄家",
    dataIndex: "bankerUserId",
    with: 100,
  },

  {
    title: "玩家UserID",
    dataIndex: "userIds",
    with: 100,
  },

  {
    title: "玩家游戏ID",
    dataIndex: "maskIds",
    with: 260,
  },

  {
    title: "玩家昵称",
    dataIndex: "nickNames",
    with: 260,
  },

  {
    title: "结算分数",
    dataIndex: "scoreCounts",
    with: 100,
  },

  {
    title: "时间",
    dataIndex: "currTime",
    with: 180,
  },

  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 140,
    align: "center",
  },
];
export default defineComponent({
  props: {
    id: {
      type: Number,
    },
    tableName: {
      type: String,
    },
  },
  components: {
    ActionInfo,
    RuleInfo,
    MatchInfo,
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const ruleInfo = ref<any>({});
    const dataSource = ref<any>({
      roomId: "",
      deskId: "",
      payType: "",
      roomCode: "",
      gameType: 0,
      playerType: "",
      basePoint: "",
      initGameCount: "",
      playGameCount: "",
      createUserId: "",
      createNickName: "",
      userIds: "",
      maskIds: "",
      nickNames: "",
      icons: "",
      scoreCountAll: "",
      startTime: "",
      endTime: "",
      deskRemark: "",
      deskRule: "",
      deskRules: {
        // isFan: false,
        // cappingRate: 0,
        // isDealer: false,
        // isRTD: false,
        // isUpG: false,
        // isDraw: false,
        // isFlower: false,
        // isFlowerDoub: false,
        // isChaosColorDoub: false,
        // isNotDAW: false,
        // isToken: false,
        // isHook: false,
        // isSeabed: false,
        // isThreeFour: true,
        // isPengExposed: false,
        // isRandomDealer: false,
        // isRandomPosIndex: false,
        // isRead: false,
        // isDissAll: false,
        // setNumG: 0,
        // isNotEat: false,
        // isNotPao: false,
        // isAuto: 0,
        // isAuthCount: 1,
      },
      dismissUserId: "",
      dismissMaskId: "",
      dismissNickNames: "",
      dismissTime: "",
    });

    const createMaskId = ref(0);

    //点击取消
    const onClose = () => {
      emit("update:id", 0);
      emit("close");
    };

    const userList = ref<any>([]);
    const gameInfoLogs = ref<any>([]);
    const actionLogs = ref<any>({});
    const actionLogsVisible = ref(false);
    const actionsLogsId = ref(0);
    const actionsLogsTableName = ref("");

    //获取数据
    const getData = async () => {
      loading.value = true;
      const { data } = await http.get(`us_group_info_logs/${props.tableName}/${props.id}`);
      dataSource.value = data.data;
      gameInfoLogs.value = data.gameInfoLogs;
      createMaskId.value = data.createMaskId;
      ruleInfo.value = data.ruleInfo;

      //格式化玩家信息
      const userIds = dataSource.value.userIds.split(",");
      const maskIds = dataSource.value.maskIds.split(",");
      const nickNames = dataSource.value.nickNames.split(",");
      const icons = dataSource.value.icons.split(",");
      const scoreCountAll = dataSource.value.scoreCountAll.split(",");
      userIds.forEach((item: any, index: any) => {
        userList.value.push({
          userId: item,
          maskId: maskIds[index],
          nickName: nickNames[index],
          icon: icons[index],
          score: scoreCountAll[index],
        });
      });

      dataSource.value.deskRules = JSON.parse(dataSource.value.deskRule);

      loading.value = false;
    };

    //查看某一场次的行牌记录
    const getActions = async (row: any) => {
      actionsLogsId.value = row.id;
      actionsLogsTableName.value = row.tableName;
      nextTick(() => {
        actionLogsVisible.value = true;
      });
    };

    onMounted(() => {
      getData();
    });

    //当前选中的是否扑克
    const isPkType = computed(() => {
      return IsPkType(dataSource.value.gameType as number);
    });

    //是否陵水十三张
    const isSsPkType = computed(() => {
      return IsSsPkType(dataSource.value.gameType as number);
    });

    //是否儋州地主牌
    const isLldPkType = computed(() => {
      return IsLldPkType(dataSource.value.gameType as number);
    });

    return {
      loading,
      onClose,
      dataSource,
      gameTypes,
      roomChargeTypes,
      getLabelByValue,
      gamePlayerTypes,
      columns,
      userList,
      DeskFanList,
      gameInfoLosColums,
      gameInfoLogs,
      getActions,
      createMaskId,
      actionLogs,
      actTypes,
      hnmjCardImgList,
      ActTypeFlower,
      getIconByValue,
      actionLogsVisible,
      actionsLogsId,
      actionsLogsTableName,
      autoCountDissTypes,
      isPkType,
      isSsPkType,
      isLldPkType,
      ruleInfo,
    };
  },
});
</script>
