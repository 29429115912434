<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '100px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-row type="flex" align="top" :gutter="10">
              <a-col :flex="1">
                <a-form-item label="牌局开始时间" name="startTime">
                  <a-date-picker v-model:value="search.data.startTime" :disabled-date="disabledDate" :allowClear="false" inputReadOnly placeholder="开始时间" style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>

              <a-col> <div style="margin-top: 5px">~</div> </a-col>

              <a-col :flex="1">
                <a-form-item label="" name="endTime">
                  <a-date-picker v-model:value="search.data.endTime" :disabled-date="disabledDate" :allowClear="false" inputReadOnly placeholder="结束时间" style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="玩家游戏ID" name="imPlayerId">
              <a-input v-model:value.number="search.data.imPlayerId" placeholder="请输入玩家游戏ID" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="邀请码" name="roomCode">
              <a-input v-model:value="search.data.roomCode" placeholder="请输入邀请码" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="游戏类型" name="gameType">
              <a-select v-model:value="search.data.gameType" placeholder="请选择游戏类型" allowClear>
                <a-select-option :value="item.value" v-for="item in gameTypes" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="userId" name="userId">
              <a-input v-model:value.number="search.data.userId" placeholder="请输入userId" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="付费类型" name="payType">
              <a-select v-model:value="search.data.payType" placeholder="请选择付费类型" allowClear>
                <a-select-option :value="item.value" v-for="item in roomChargeTypes" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <!-- <a-col v-bind="searchCol">
            <a-form-item label="桌子号" name="deskId">
              <a-input v-model:value.number="search.data.deskId" placeholder="请输入桌子号" allowClear> </a-input>
            </a-form-item>
          </a-col> -->

          <!-- <a-col v-bind="searchCol">
            <a-form-item label="变化类型" name="rechargeType">
              <a-select v-model:value="search.data.rechargeType" placeholder="请选择适用性别" allowClear>
                <a-select-option :value="item.value" v-for="item in CurrencyRechargeType" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
        </l-search-container>
      </a-form>
    </template>

    <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="index" :loading="loading">
      <!-- <template v-slot:toolbar-action>
        <l-auth-button auth="app_version_create_btn" type="primary" @click="visible = true">
          <PlusOutlined />
          新增
        </l-auth-button>
      </template> -->

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'changeAmount'">
          <span v-show="record.changeAmount > 0" class="l-font-success l-bold"> +{{ record.changeAmount }} </span>
          <span v-show="record.changeAmount <= 0" class="l-font-danger l-bold"> {{ record.changeAmount }} </span>
        </template>

        <template v-if="column.dataIndex === 'createUser'">{{ record.createNickName }}(userId：{{ record.createUserId }},maskId:{{ record.createMaskId }})</template>

        <template v-if="column.dataIndex === 'payType'">{{ getLabelByValue(roomChargeTypes, record.payType) }}</template>

        <template v-if="column.dataIndex === 'gameType'">{{ getLabelByValue(gameTypes, record.gameType) }}</template>

        <template v-if="column.dataIndex === 'playerType'">{{ getLabelByValue(gamePlayerTypes, record.playerType) }}</template>

        <template v-if="column.dataIndex === 'timeRange'">{{ record.startTime + " ~ " + record.endTime }}</template>

        <template v-if="column.dataIndex === 'dismissUser'">
          {{ record.dismiss_nick_names }}
        </template>

        <template v-if="column.dataIndex === 'read_status'">
          <a-tag :color="record.read_status == 2 ? 'success' : 'error'">{{ record.state == 2 ? "已读" : "未读" }}</a-tag>
        </template>

        <template v-if="column.dataIndex === 'action'">
          <l-auth-text-button auth="us_group_info_logs_info_btn" type="primary" primary @click="showInfo(record)">
            <ExclamationCircleOutlined />
            详情
          </l-auth-text-button>
        </template>
      </template>
    </l-table>

    <l-drawer title="牌局详情" v-model:visible="infoVisible" width="90%" maskClosable mask :body-style="{ paddingBottom: '80px' }">
      <Info ref="edit_ref" @close="infoVisible = false" v-model:id="infoId" :table-name="infoTableName" />
    </l-drawer>
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { getLabelByValue, roomChargeTypes, gameTypes, gamePlayerTypes } from "@/common/head";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { dateIsBetween, getPageOffset } from "@/common/utils";
import Info from "./Info.vue";
import { Dayjs } from "dayjs";
import { PaginationProps } from "ant-design-vue";

const columns = [
  {
    title: "序号",
    width: 80,
    dataIndex: "index",
    // fixed: "left",
  },

  {
    title: "房间ID",
    width: 80,
    dataIndex: "roomId",
    ellipsis: true,
  },

  {
    title: "桌子ID",
    width: 80,
    dataIndex: "deskId",
    ellipsis: true,
  },

  {
    title: "付费类型",
    width: 90,
    dataIndex: "payType",
    ellipsis: true,
  },

  {
    title: "邀请码",
    width: 90,
    dataIndex: "roomCode",
    ellipsis: true,
  },

  {
    title: "游戏类型",
    width: 120,
    dataIndex: "gameType",
    ellipsis: true,
  },

  {
    title: "游戏人数",
    width: 80,
    dataIndex: "playerType",
    ellipsis: true,
  },

  {
    title: "基础分",
    width: 70,
    dataIndex: "basePoint",
    ellipsis: true,
  },

  {
    title: "倍率",
    width: 70,
    dataIndex: "rateNum",
    ellipsis: true,
  },

  {
    title: "开的局数",
    width: 80,
    dataIndex: "initGameCount",
    ellipsis: true,
  },

  {
    title: "真实局数",
    width: 80,
    dataIndex: "playGameCount",
    ellipsis: true,
  },

  {
    title: "创建人",
    width: 250,
    dataIndex: "createUser",
  },

  {
    title: "参与人的userID",
    width: 240,
    dataIndex: "userIds",
  },

  {
    title: "参与人的游戏ID",
    width: 280,
    dataIndex: "maskIds",
  },

  {
    title: "参与人的昵称",
    width: 400,
    dataIndex: "nickNames",
  },

  {
    title: "分数",
    width: 100,
    dataIndex: "scoreCountAll",
    ellipsis: true,
  },

  {
    title: "开始-结束时间",
    width: 320,
    dataIndex: "timeRange",
    ellipsis: true,
  },

  {
    title: "桌子描述",
    width: 120,
    dataIndex: "deskRemark",
    ellipsis: true,
  },

  // {
  //   title: "桌子规则",
  //   width: 120,
  //   dataIndex: "deskRule",
  //   ellipsis: true,
  // },

  {
    title: "解散发起人userId",
    width: 140,
    dataIndex: "dismissUserId",
    ellipsis: true,
  },

  {
    title: "解散发起人游戏ID",
    width: 140,
    dataIndex: "dismissMaskId",
    ellipsis: true,
  },

  {
    title: "解散发起人昵称",
    width: 140,
    dataIndex: "dismissNickNames",
    ellipsis: true,
  },

  {
    title: "解散时间",
    width: 180,
    dataIndex: "dismissTime",
    ellipsis: true,
  },

  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 100,
    align: "center",
  },
];

export default defineComponent({
  name: "us_group_info_logs",
  components: {
    Info,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        imPlayerId: undefined,
        gameType: undefined,
        userId: undefined,
        startTime: "",
        endTime: "",
        roomId: undefined,
        deskId: undefined,
        payType: undefined,
        roomCode: "",
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    const infoId = ref(0);
    const infoTableName = ref("");
    const infoVisible = ref(false);

    //开始结束时间
    const validTimeRange = reactive({
      minDate: "",
      maxDate: "",
    });

    //获取不可选择的时间
    const disabledDate = (currentDate: Dayjs) => {
      return !dateIsBetween(validTimeRange.minDate, validTimeRange.maxDate, currentDate);
    };

    //首次进入，获取可选的日期范围
    const getTime = async () => {
      loading.value = true;
      const { data } = await http.get("us_group_info_logs/time");
      validTimeRange.minDate = data.minDate;
      validTimeRange.maxDate = data.maxDate;
      search.data.startTime = data.maxDate;
      search.data.endTime = data.maxDate;

      onSearch();
    };

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_group_info_logs", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      let offset = getPageOffset(pagination.current!, pagination.pageSize!);
      data.value = result.data.data.map((item: any) => {
        item.index = ++offset;
        return item;
      });

      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = validTimeRange.maxDate;
      search.data.endTime = validTimeRange.maxDate;
      onSearch();
    };

    //显示详情
    const showInfo = (row: any) => {
      infoId.value = row.id;
      infoTableName.value = row.tableName;
      nextTick(() => {
        infoVisible.value = true;
      });
    };

    onMounted(() => {
      getTime();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      roomChargeTypes,
      gameTypes,
      gamePlayerTypes,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      disabledDate,
      getLabelByValue,
      showInfo,
      infoId,
      infoVisible,
      infoTableName,
    };
  },
});
</script>
